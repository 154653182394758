import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Whakamatakitaki from "../components/whakamatakitaki";
import Kupu from "../components/kupu";

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function keiTeTari() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKeiTeTari {
        edges {
          node {
            termEnglish
            termMaori
            kupuEnglish
            kupuMaori
          }
        }
      }
    }
  `);
  const info = shuffle(data.allContentfulKeiTeTari.edges);
  return (
      <Layout>
        <SEO title="Kei te Tari" />
        <Whakamatakitaki collectionName="Kei te Tari" subtitle="At the office">
          {info.map(x => (
            <Kupu node={x.node} />
          ))}
        </Whakamatakitaki>
      </Layout>
  );
}

export default keiTeTari